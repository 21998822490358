.form {
  overflow-y: scroll;
  margin-top: 3rem;
  padding: 0 3rem;
  width: 100%;
}

.label {
  display: block;
  width: fit-content;
  margin-bottom: 1.1rem;
  color: #111827;
  font-size: 1.8rem;
  font-weight: 500;
}

input, textarea {
  display: block;
  padding: 1.2rem;
  background-color: #fff;
  color: #111827;
  font-size: 1.6rem;
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid #e0e0e0;
}

.addMoreBtn {
  display: block;
  padding: 1.2rem 1rem;
  margin-bottom: 3rem;
  background-color: #1f2937;
  color: #ffffff;
  font-size: 1.6rem;
  font-weight: 700;
  max-width: 20rem;
  border-radius: 0.5rem;
  outline: none;
  border: none;
  cursor: pointer;
}

.removeBtn {
  font-size: 1.5rem;
  padding: 0 1.8rem;
  background-color: #dc2626;
  color: #ffffff;
  border-radius: 0.4rem;
  outline: none;
  border: none;
  cursor: pointer;
}

.submitBtn {
  padding: 1.2rem 0;
  margin-bottom: 1.25rem;
  font-weight: 700;
  background-color: #1f2937;
  color: #fff;
  font-size: 1.6rem;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}
