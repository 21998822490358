.header {
  display: flex;
  width: 100%;
  margin: 2rem 0;
  justify-content: space-between;
  align-items: center;
}

.headertext {
  font-size: 2.8rem;
  font-weight: 600;
}

.headerBtn {
  padding: 1rem;
  font-size: 1.6rem;
  background-color: #1f2937;
  color: #ffffff;
  border-radius: 0.375rem;
  outline: none;
  border: none;
  cursor: pointer;
}

table {
  overflow: hidden;
  text-align: center;
  min-width: 100%;
  border: 1px solid #d1d5db;
  border-collapse: collapse;
}

th {
  padding: 1.5rem 0;
  color: #111827;
  font-size: 2rem;
  font-weight: 500;
  border: 1px solid #d1d5db;
}

.tableData {
  color: #111827;
  font-size: 1.4rem;
  font-weight: 400;
  white-space: nowrap;
  border: 1px solid #d1d5db;
}

.tableCompleted {
  font-size: 1.5rem;
  font-weight: 400;
  white-space: nowrap;
  border: 1px solid #d1d5db;
}

.tableDataActions {
  color: #111827;
  padding: 1.4rem 2rem;
  font-size: 1.4rem;
  font-weight: 400;
  border: 1px solid #d1d5db;
}

.viewQuestionsBtn {
  display: inline-block;
  padding: 1rem;
  margin-right: 0.5rem;
  background-color: #1f2937;
  color: #ffffff;
  width: 48%;
  border-radius: 0.375rem;
  outline: none;
  border: 0;
  cursor: pointer;
}

.deleteBtn {
  display: inline-block;
  padding: 1rem;
  background-color: #dc2626;
  color: #ffffff;
  width: 48%;
  border-radius: 0.375rem;
  cursor: pointer;
  outline: none;
  border: 0;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  margin-right: 3rem;
  width: 5rem;
  height: 2.5rem;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  width: 21px;
  height: 21px;
  background-color: #111827;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(25px);
  background-color: #ffffff;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: #111827;
}

.toggle-switch-label {
  font-size: 1.5rem;
  margin-right: 1.2rem;
  user-select: none;
}
