.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 1.8rem 3rem;
  background-color: #1f2937;
  flex-wrap: wrap;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.headWrapper {
  display: flex;
  color: #ffffff;
  align-items: center;
  cursor: pointer;
}

.navHead {
  font-size: 2rem;
  font-weight: 600;
}

.navBtn {
  color: #ffffff;
  border-radius: 0.25rem;
  border: 1px solid #6b7280;
  background: #1f2937;
  font-size: 1.6rem;
  padding: 1rem;
  outline: none;
  cursor: pointer;
}