.loginPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 3rem;
  width: 100vw;
  height: 100vh;
}

.loginHead {
  color: #111827;
  font-size: 2.4rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  text-align: center;
}

.loginForm {
  margin-top: 2rem;
  min-width: 30vw;
}

.loginInput {
  display: block;
  position: relative;
  padding: 1rem;
  font-size: 1.6rem;
  color: #111827;
  width: 100%;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  border: 1px solid #d1d5db;
  appearance: none;
  outline: none;
}

.loginBtn {
  display: flex;
  position: relative;
  padding: 0.8rem 1rem;
  background-color: #1f2937;
  color: #ffffff;
  font-size: 1.54rem;
  margin-top: 2rem;
  font-weight: 500;
  justify-content: center;
  width: 100%;
  border-radius: 0.375rem;
}

.loginBtn:hover {
  background-color: #1f2937;
}
